import { cx } from '@emotion/css';
import type { FC } from 'react';

import type { AnswerOptionProps } from '../Questionnaire/types';
import { QuestionType } from '../Questionnaire/types';
import { AnswerOption } from './AnswerOption/AnswerOption';
import { AnswerOptionWithImage } from './AnswerOptionWithImage';
import { questionnaireAnswerContainerCss, questionnaireAnswerGridContainerCss } from './styles';
import type { QuestionnaireAnswerProps } from './types';

export const QuestionnaireAnswer: FC<QuestionnaireAnswerProps> = ({
  onAnswerChange,
  answers,
  questionType,
}) => {
  const useImageButtonLayout = answers.some(answer => !!answer.imageUrl);

  const handleAnswerChange = (newAnswer: AnswerOptionProps) => {
    if (questionType === QuestionType.SINGLE_CHOICE || questionType === QuestionType.YES_NO) {
      answers.forEach(item => {
        item.isSelected = false;
      });
    }

    const updatedAnswers = answers.map(answer => {
      const isSameAnswer = answer.id === newAnswer.id;
      return isSameAnswer ? newAnswer : answer;
    });

    onAnswerChange(updatedAnswers);
  };

  return (
    <div
      className={cx(
        useImageButtonLayout ? questionnaireAnswerGridContainerCss : questionnaireAnswerContainerCss
      )}
    >
      {answers.map(answer =>
        useImageButtonLayout ? (
          <AnswerOptionWithImage
            key={answer.id}
            answer={answer}
            onAnswerChange={handleAnswerChange}
            questionType={questionType}
          />
        ) : (
          <AnswerOption
            key={answer.id}
            answer={answer}
            onAnswerChange={handleAnswerChange}
            questionType={questionType}
          />
        )
      )}
    </div>
  );
};

QuestionnaireAnswer.displayName = 'QuestionnaireAnswer';
