import { cx } from '@emotion/css';
import { Icon } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { QuestionType } from '../../Questionnaire/types';
import type { AnswerOptionProps } from '../types';
import {
  checkboxBorderRadiusCss,
  questionnaireRadioContainerCss,
  questionnaireRadioCss,
  questionnaireRadioLabelCss,
  yesNoButtonWrapperCss,
} from './styles';

export const AnswerOption: FC<AnswerOptionProps> = ({ answer, questionType, onAnswerChange }) => {
  if (questionType === QuestionType.YES_NO) {
    return <YesNoAnswer answer={answer} onAnswerChange={onAnswerChange} />;
  }

  const type = questionType === QuestionType.MULTIPLE_CHOICE ? 'checkbox' : 'radio';
  return (
    <label className={cx(questionnaireRadioContainerCss)}>
      <input
        readOnly
        onClick={() => onAnswerChange({ ...answer, isSelected: !answer.isSelected })}
        type={type}
        name="questionnaireAnswer"
        checked={!!answer.isSelected}
      />
      <Icon
        className={cx(
          questionnaireRadioCss,
          questionType === QuestionType.MULTIPLE_CHOICE && checkboxBorderRadiusCss
        )}
        name="check"
      />
      <span className={cx(questionnaireRadioLabelCss)}>{answer.text}</span>
    </label>
  );
};

AnswerOption.displayName = 'AnswerOption';

const YesNoAnswer: FC<Omit<AnswerOptionProps, 'questionType'>> = ({ answer, onAnswerChange }) => {
  return (
    <label className={cx(yesNoButtonWrapperCss)}>
      <input
        readOnly
        onChange={() => onAnswerChange({ ...answer, isSelected: !answer.isSelected })}
        type="radio"
        name="questionnaireAnswer"
        checked={!!answer.isSelected}
      />
      <span>{answer.text}</span>
    </label>
  );
};
