import { css } from '@emotion/css';
import {
  FontParagraph,
  Heading,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const questionnaireIntroContainerCss = css`
  width: 100%;
  margin-bottom: 28px;

  h1 {
    ${Heading.H100}
    margin-bottom: 24px;

    ${mobileMediaQuery} {
      margin-top: 0;
    }
  }

  p {
    ${FontParagraph.P100}
    margin-bottom: 30px;

    ${nonMobileMediaQuery} {
      margin-bottom: 38px;
    }
  }
`;
