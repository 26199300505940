import { css } from '@emotion/css';
import { mobileMediaQuery, ZIndex as zIndex } from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

export const questionnaireFooterCss = css`
  ${mobileMediaQuery} {
    z-index: ${zIndex.QUESTIONNAIRE_FOOTER};
  }
`;

export const containerCss = css`
  height: calc(100vh - var(${totalHeaderHeightCssVar}));
  display: grid;
  grid-template-rows: [questionnaire-main] 1fr [questionnaire-footer] auto;
`;
