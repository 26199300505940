import { Partition } from '@snapchat/graphene';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';

import type { SiteConfiguration } from '../../configTypes';
import { BlizzardEventFormat, LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'forbusiness.snapchat.com',
  theme: {
    defaultPageBackgroundColor: BackgroundColor.White,
  },

  featureFlags: {
    disableLazyLoadLogging: 'true',
  },

  globalNavProps: {
    defaultGroupKey: 'advertisers',
    backgroundColor: BackgroundColor.Black,
    siteName: 'Business',
  },
  trackingSettings: {
    cookieDomain: 'snapchat.com',
    eventListeners: [
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.FOR_BUSINESS },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: 'UA-41740027-30',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'for-business',
        dsn: 'https://bd3f9142c7b84f6091348eddeb245cd3@sentry.sc-prod.net/65',
      },
      {
        vendor: LoggingVendor.BLIZZARD,
        eventFormat: BlizzardEventFormat.FOR_BUSINESS,
      },
    ],
  },
  redirects: {
    hostRedirects: {},
    queryRedirects: {
      category: 'select_category',
      industry: 'select_industry',
      'ad-product': 'select_ad-product',
      objective: 'select_objective',
      region: 'select_region',
      'partner-type': 'select_partner-type',
      worldwide: 'select_worldwide',
      'kingdom-of-saudi-arabia': 'select_kingdom-of-saudi-arabia',
      'united-arab-emirates': 'select_united-arab-emirates',
      'united-kingdom': 'select_united-kingdom',
    },
  },
  persistentQueryParams: [
    '_sid',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'gclid',
  ],

  persistQueryParamsDomains: [
    'ads.snapchat.com',
    'businesshelp.snapchat.com',
    'developers.snapchat.com',
    'lensstudio.snapchat.com',
    'snapchatexplore.exceedlms.com',
    'snappublisher.snapchat.com',
    'trends.snapchat.com',
  ],

  csp: {
    frameSrc: [
      'https://snapchat.chilipiper.com/', // Chili Piper
    ],
    styleSrc: [
      'https://js.chilipiper.com', // Has assets used by Chili Piper popup - https://help.chilipiper.com/hc/en-us/articles/360053800373-Concierge-Snippet-and-JS-API
    ],
    imgSrc: [
      'https://js.chilipiper.com', // Has assets used by Chili Piper popup
    ],
    scriptSrc: [
      'https://js.chilipiper.com', // Chili Piper popup JS
      `'unsafe-hashes'`,
      `'sha256-hg6MIJBZi9U2ZxOyi0rQZ5E7DP6tzaQ4zvKbrTQKXGw='`,
    ],
    connectSrc: [
      'https://api.chilipiper.com', // Chili Piper
    ],
  },

  tracing: {
    samplingRate: 0.25,
  },
};
