import { css } from '@emotion/css';
import {
  Heading,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const questionnaireQuestionContainerCss = css`
  width: 100%;

  h1 {
    ${Heading.H100}
    margin-bottom: 24px;
    margin-top: 14px;
    word-break: break-word;

    ${mobileMediaQuery} {
      margin-top: 0;
    }
  }

  p:first-child {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 18px;
  }

  p:last-child {
    margin-bottom: 30px;

    ${nonMobileMediaQuery} {
      margin-bottom: 38px;
    }
  }
`;
