import { gql } from '@apollo/client';
import type { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { ContentfulTypedSysProps } from 'src/types/contentful';
import type { RichText } from 'src/types/RichText';

import type { FormDataProps } from '../../../../components/Form';
import { formFragments } from '../../../../components/Form';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

const chiliPiperFormFragment = gql`
  fragment ChiliPiperFormBlockFragment on ChiliPiperFormBlock {
    ...ContentfulSysId
    title
    subtitle {
      json
    }
    routerName
    form {
      ...FormAll
    }
    formId
    confirmationTitle
    confirmationText {
      json
    }
    backgroundColor
    anchorId
  }
  ${formFragments.all}
  ${contentfulSysIdFragment}
`;

export interface ChiliPiperFormDataProps extends ContentfulTypedSysProps<'ChiliPiperFormBlock'> {
  title?: string;
  subtitle?: RichText;
  routerName?: string;
  form?: FormDataProps;
  formId?: string;
  confirmationTitle?: string;
  confirmationText?: RichText;
  backgroundColor?: BackgroundColor;
  anchorId?: string;
}

// =================================================================================================
// ChiliPiperForm Query
// =================================================================================================

export const chiliPiperFormQuery = gql`
  query ChiliPiperForm($id: String!, $locale: String!, $preview: Boolean!) {
    chiliPiperFormBlock(id: $id, locale: $locale, preview: $preview) {
      ...ChiliPiperFormBlockFragment
    }
  }
  ${chiliPiperFormFragment}
`;

export interface ChiliPiperFormQueryData {
  chiliPiperFormBlock: ChiliPiperFormDataProps;
}
