import type { FC, ReactNode } from 'react';

import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { ChiliPiperFormBlock } from './ChiliPiperFormBlock';
import type { ChiliPiperFormQueryData } from './ChiliPiperFormQuery';
import { chiliPiperFormQuery } from './ChiliPiperFormQuery';

export const ChiliPiperFormBlockShallow: FC<{
  id: string;
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}> = props => {
  const id = props.id;

  const { data } = useContentfulQuery<ChiliPiperFormQueryData, ContentfulIdVariable>(
    chiliPiperFormQuery,
    {
      variables: {
        id,
      },
    }
  );

  if (!data) {
    return null;
  }

  return (
    <ChiliPiperFormBlock
      {...data.chiliPiperFormBlock}
      preChildren={props.preChildren}
      postChildren={props.postChildren}
    />
  );
};
