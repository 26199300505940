import {
  BackgroundColor,
  BlockSplitPanel as BlockSplitPanelSDS,
  Button as ButtonSDS,
  ButtonType,
  largeDesktopWidth,
  useWindowSize,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { questionnaireIntroContainerCss } from './styles';
import type { QuestionnaireIntroProps } from './types';

export const QuestionnaireIntro: FC<QuestionnaireIntroProps> = ({
  title,
  description,
  imageSrcUrl,
  buttonText,
  onClick,
}) => {
  const { width: windowWidth } = useWindowSize();
  const [isLargeDesktop, setIsLargeDesktop] = useState<boolean>(false);

  useEffect(() => {
    setIsLargeDesktop((windowWidth ?? Number.POSITIVE_INFINITY) > largeDesktopWidth);
  }, [windowWidth, setIsLargeDesktop]);

  return (
    <BlockSplitPanelSDS
      swapPanels={!isLargeDesktop}
      contentLeft={
        <div className={questionnaireIntroContainerCss}>
          <h1>{title}</h1>
          {renderRichText(description)}
          <ButtonSDS onClick={onClick} type={ButtonType.Primary}>
            {buttonText}
          </ButtonSDS>
        </div>
      }
      //Hardcoded background colors due to the component designs only supporting the color combination Gray/White
      leftBackgroundProps={{
        backgroundColor: BackgroundColor.Gray,
      }}
      rightBackgroundProps={{
        backgroundImageUrl: imageSrcUrl,
        backgroundColor: BackgroundColor.White,
      }}
    />
  );
};

QuestionnaireIntro.displayName = 'QuestionnaireIntro';
