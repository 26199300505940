import { css } from '@emotion/css';
import { nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const questionnaireAnswerContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  width: 100%;

  ${nonMobileMediaQuery} {
    gap: 18px;
  }
`;

export const questionnaireAnswerGridContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  width: 100%;

  ${nonMobileMediaQuery} {
    display: inline-grid;
    grid-template-columns: auto minmax(0, 1fr);
    width: auto;
  }
`;
