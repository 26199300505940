import {
  BackgroundColor,
  BlockSplitPanel as BlockSplitPanelSDS,
} from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { QuestionnaireAnswer } from '../QuestionnaireAnswer';
import { questionnaireQuestionContainerCss } from './styles';
import type { QuestionnaireQuestionProps } from './types';

export const QuestionnaireQuestion: FC<QuestionnaireQuestionProps> = ({
  questionType,
  image,
  question,
  currentStep,
  totalSteps,
  body,
  answers,
  onAnswerChange,
}) => {
  return (
    <BlockSplitPanelSDS
      contentLeft={
        <div className={questionnaireQuestionContainerCss}>
          <p>
            Step {currentStep} of {totalSteps}
          </p>
          <h1>{question}</h1>
          {renderRichText(body)}
        </div>
      }
      contentRight={
        <QuestionnaireAnswer
          questionType={questionType}
          answers={answers}
          onAnswerChange={onAnswerChange}
        />
      }
      //Hardcoded background colors due to the component designs only supporting the color combination Gray/White
      leftBackgroundProps={{
        backgroundImageUrl: image,
        backgroundColor: BackgroundColor.Gray,
        showCurtain: !!image,
      }}
      rightBackgroundProps={{
        backgroundColor: BackgroundColor.White,
      }}
    />
  );
};

QuestionnaireQuestion.displayName = 'QuestionnaireQuestion';
