import { gql } from '@apollo/client';

import { fragments as analyticsFragments } from '../../../../components/Analytics/query';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragments = {
  all: gql`
    fragment MobileCtaAll on MobileCta {
      ...ContentfulSysId
      backgroundColor
      body
      buttonColor
      url
      analytics {
        ...AnalyticsAll
      }
    }
    ${contentfulSysIdFragment}
    ${analyticsFragments.all}
  `,
};

export const query = gql`
  query MobileCtaBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    mobileCta(preview: $preview, locale: $locale, id: $id) {
      ...MobileCtaAll
    }
  }
  ${fragments.all}
`;
