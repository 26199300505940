import { css } from '@emotion/css';
import { Black, Green, White, Yellow } from '@snapchat/snap-design-system-marketing';

export const questionnaireRadioContainerCss = css`
  cursor: pointer;
  position: relative;

  input {
    visibility: none;
    height: 0;
    width: 0;
    display: none;
    appearance: none;

    ~ i {
      &::before {
        color: ${White};
        font-size: 10px;
        left: 2px;
        position: absolute;
        top: 2px;
      }
    }

    &:checked {
      ~ i {
        /* 
        This color is not compatible for all backgrounds but is used per discussion
        w/ Snap design team with a future follow up action to adjust color when appropriate. 
        */
        background-color: ${Green.V150};
        border-color: ${Green.V150};
      }

      ~ span {
        font-weight: 600;
      }
    }
  }
`;

export const questionnaireRadioLabelCss = css`
  margin-left: 20px;
`;

export const questionnaireRadioCss = css`
  background-color: ${White};
  border-radius: 50%;
  border: 1px solid ${Black.V50};
  height: 15px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 15px;
`;

export const checkboxBorderRadiusCss = css`
  border-radius: 4px;
`;

export const yesNoButtonWrapperCss = css`
  width: 220px;
  display: flex;
  text-align: center;
  justify-content: center;

  span {
    display: flex;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: ${Black.V50};
    background-color: ${White};
    color: ${Black.V50};
    border-radius: 26px;
    font-family: inherit;
    font-size: 36px;
    min-height: 110px;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked ~ span {
      font-weight: 600;
      border-width: 1px;
      border-style: solid;
      border-color: ${Yellow.V100};
      background-color: ${Yellow.V100};
      color: ${Black.V200};
    }
  }
`;
