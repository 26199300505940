import type { FC } from 'react';

import type { BlockDataProps } from '../../../../components/Block';
import { Block } from '../../../../components/Block';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { query } from './queries';
import type {
  AnswerBlocksData,
  QuestionnaireResultBlockProps,
  QuestionnaireResultsProps,
} from './types';

export const QuestionnaireResults: FC<QuestionnaireResultsProps> = ({ answersIds }) => {
  const blocks = answersIds?.map((id, index) => (
    <QuestionnaireResultBlocks key={`id-${index}`} id={id} />
  ));

  return blocks ? <>{blocks}</> : null;
};

QuestionnaireResults.displayName = 'QuestionnaireResults';

const QuestionnaireResultBlocks: FC<QuestionnaireResultBlockProps> = ({ id }) => {
  const { data } = useContentfulQuery<AnswerBlocksData, ContentfulIdVariable>(query, {
    variables: { id },
  });

  const blocksData: BlockDataProps[] | undefined =
    data?.questionnaireAnswer?.resultsContentCollection?.items;

  const blocks = blocksData?.map((blockProps, index) => (
    <Block
      key={`result-block-${blockProps.sys.id}-${index}`}
      {...blockProps}
      backgroundColor={blockProps.backgroundColor}
      backgroundMediaV2={blockProps.backgroundMediaV2}
      skipBreakTemplate={!!blockProps.backgroundMediaV2}
    />
  ));

  return blocks ? <>{blocks}</> : null;
};
