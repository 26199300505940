import { cx } from '@emotion/css';
import { Media } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { QuestionType } from '../../Questionnaire/types';
import type { AnswerOptionProps } from '../types';
import { buttonContainerCss, buttonImageCss, buttonWrapperCss } from './styles';

export const AnswerOptionWithImage: FC<AnswerOptionProps> = ({
  answer,
  questionType,
  onAnswerChange,
}) => {
  const type = questionType === QuestionType.MULTIPLE_CHOICE ? 'checkbox' : 'radio';
  return (
    <label className={cx(buttonWrapperCss)}>
      <input
        readOnly
        onClick={() => onAnswerChange({ ...answer, isSelected: !answer.isSelected })}
        type={type}
        name="questionnaireAnswer"
        checked={!!answer.isSelected}
      />
      <div className={cx(buttonContainerCss)}>
        <Media className={cx(buttonImageCss)} imageSource={answer.imageUrl} />
        <span>{answer.text}</span>
      </div>
    </label>
  );
};

AnswerOptionWithImage.displayName = 'AnswerOptionWithImage';
