import type { QuestionnaireProps } from '../types';
import { QuestionType } from '../types';

export const questionnaireMockData: QuestionnaireProps = {
  title: 'Questionnaire Block',
  body: undefined,
  image:
    'https://images.ctfassets.net/inb32lme5009/129Cd5cKAcFMkh2OrXLROl/ed47f0cf5de55b087c32a2c659a35960/Snap_Sales_Wave2_EmailSupport_Successful-Scaling-1104x736.png',
  submitLabel: 'Submit',
  startLabel: 'Start survey',
  nextLabel: 'Next',
  backLabel: 'Previous',
  skipLabel: 'Skip',
  questions: [
    {
      id: 'xyz-001',
      question: 'Question 1',
      body: undefined,
      questionType: QuestionType.SINGLE_CHOICE,
      answers: [
        {
          id: '2SptTLM1KfjnH2sqX0M4VO',
          text: 'Answer 1',
          isSelected: false,
        },
        {
          id: '1O73uFCJ6ZCvCiNqOSe5qc',
          text: 'or this one',
          isSelected: false,
        },
      ],
      required: true,
    },
    {
      id: 'xyz-002',
      question: 'What industry are they in?',
      body: undefined,
      questionType: QuestionType.SINGLE_CHOICE,
      answers: [
        {
          id: '3fzalX1EB5q9DPAz9fE2Q6',
          text: 'Agency',
          imageUrl:
            'https://images.ctfassets.net/inb32lme5009/ydxwyy41HfWl2W3tzryuF/3b1c2521e4b664b34aa740f8367454b1/Snapchat_001_Paid_QuickRevisionsPaidSearch_Image2a_Gradient_1200x1200__1_.jpg',
          isSelected: false,
        },
        {
          id: '5U7WOJ0JdfKbSC923r15Vy',
          text: 'Auto',
          imageUrl:
            'https://images.ctfassets.net/inb32lme5009/6thjJusOakQ1Llb4q8ys4h/9b1c7a52ea0c8515c959b7413f90d646/laughing-emoji.png',
          isSelected: false,
        },
      ],
      required: true,
    },
    {
      id: 'xyz-003',
      question: 'Can you select more than one here?',
      body: undefined,
      questionType: QuestionType.MULTIPLE_CHOICE,
      answers: [
        {
          id: '64VaIT0C2UVKUdpXvPO1e9',
          text: 'This one here',
          isSelected: false,
        },
        {
          id: '1O73uFCJ6ZCvCiNqOSe5qc',
          text: 'or this one',
          isSelected: false,
        },
        {
          id: '2SptTLM1KfjnH2sqX0M4VO',
          text: 'Answer 1',
          isSelected: false,
        },
      ],
      required: true,
    },
    {
      id: 'xyz-004',
      question: 'yes or no?',
      body: undefined,
      questionType: QuestionType.YES_NO,
      answers: [
        {
          id: 'fVBxRUo2lOd4yyLqPRS2C',
          text: 'Yes',
          isSelected: false,
        },
        {
          id: '4Fs0wuSddNL2SEXPDH2j4s',
          text: 'No',
          isSelected: false,
        },
      ],
      required: false,
    },
    {
      id: 'xyz-005',
      question: 'Multi choice with images?',
      body: undefined,
      questionType: QuestionType.MULTIPLE_CHOICE,
      image:
        'https://images.ctfassets.net/inb32lme5009/3Kmv8R1HldV2JEaQPpEQrY/965bf4c723fbcc9181e75027ae8d5e9f/Illinois_Lottery_Filter.png',
      answers: [
        {
          id: '5U7WOJ0JdfKbSC923r15Vy',
          text: 'Auto',
          imageUrl:
            'https://images.ctfassets.net/inb32lme5009/6thjJusOakQ1Llb4q8ys4h/9b1c7a52ea0c8515c959b7413f90d646/laughing-emoji.png',
          isSelected: false,
        },
        {
          id: '3fzalX1EB5q9DPAz9fE2Q6',
          text: 'Agency',
          imageUrl:
            'https://images.ctfassets.net/inb32lme5009/ydxwyy41HfWl2W3tzryuF/3b1c2521e4b664b34aa740f8367454b1/Snapchat_001_Paid_QuickRevisionsPaidSearch_Image2a_Gradient_1200x1200__1_.jpg',
          isSelected: false,
        },
      ],
      required: true,
    },
    {
      id: 'xyz-006',
      question: 'This is a mandatory yes no?',
      body: undefined,
      questionType: QuestionType.YES_NO,
      answers: [
        {
          id: 'fVBxRUo2lOd4yyLqPRS2C',
          text: 'Yes',
          isSelected: false,
        },
        {
          id: '4Fs0wuSddNL2SEXPDH2j4s',
          text: 'No',
          isSelected: false,
        },
      ],
      required: true,
    },
  ],
};
