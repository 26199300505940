import { gql } from '@apollo/client';

import { blockAllFragment } from '../../../../components/Block';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragments = {
  questionnaireAllBlocks: gql`
    fragment QuestionnaireAllBlocks on QuestionnaireAnswer {
      ...ContentfulSysId
      resultsContentCollection(limit: 50) {
        items {
          ...ContentfulSysId
          ... on Block {
            ...BlockAll
          }
        }
      }
    }
    ${blockAllFragment}
    ${contentfulSysIdFragment}
  `,
};

export const query = gql`
  query QuestionnaireEntryResultsQuery($preview: Boolean!, $locale: String!, $id: String!) {
    questionnaireAnswer(preview: $preview, locale: $locale, id: $id) {
      ...QuestionnaireAllBlocks
    }
  }
  ${fragments.questionnaireAllBlocks}
`;
